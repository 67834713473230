<template>
  <div class="warpConss chat-list">
    <div v-for="(item, index) in CourseList" :key="index" class="chat-item">
      <div class="item-title" :class="item.isLive == 0 || item.isLive == 2 ? 'noLive' : ''" @click.stop="gkcb(item)">
        <div class="flex-c ellipsis">
          <img v-if="item.isLive == 0 || item.isLive == 2 || IsPurchase" class="play-img"
            src="@/assets/img/partner/audio.png">
          <img v-else class="play-img" src="@/assets/img/kecheng/lock.png">
          <span class="chapter-title ellipsis">{{ item.title }}</span> &nbsp;
          <!-- <span class="time">
            {{ item.startTime | format('yyyy.MM.dd HH:mm') }}
          </span> -->
          <span v-if="item.isTrial == 1 && item.isLive == 3 && !IsPurchase" class="tryListion">可试听</span>
        </div>

        <div class="but-list">
          <div>
            <div v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'" class="keep-learn"
              @click.stop="goData(item, 4)">
              随堂讲义
            </div>
            <div v-if="ifClass ? item.contactList.length > 0 : item.isHasContant == 'true'" class="keep-learn"
              @click.stop="cgeckTask(item)">
              随堂测试
            </div>
            <div v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'" class="keep-learn"
              @click.stop="goData(item, 2)">
              随堂资料
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogVisible" class="warpCpons" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click="tChuang">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ''
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseById } from '@/api/home'
import { IsLive } from '@/api/emun'
import { getInfo, getToken } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import { courseCheckPlay } from '@/api/know'
const answerClient = new AnswerClient()
export default {
  props: {
    id: {},
    playid: {},
    list: {},
    classId: {},
    classProductLine: {},
    ccConfig: {
      type: String
    },
    purchase: {
      type: Boolean
    },
    ifClass: {
      type: Boolean
    },
    cardCode: null,
    isFreeze: null
  },
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      IsPurchase: false,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  // props: ["id", "playid", "list", "playid", "classId", "ccConfig", "purchase"],
  watch: {
    list() {
      this.IsPurchase = this.purchase
      this.CourseList = this.list
    }
  },
  created() {
    this.userInfo = getInfo()
    if (this.list) {
      this.IsPurchase = this.purchase
      this.CourseList = this.list
    } else {
      getLbClassCourseById(this.id, this.classId).then((res) => {
        if (!this.purchase) {
          this.IsPurchase = res.data.IsPurchase
        }
        this.CourseList = res.data.details.liveshowplaybackList
      })
    }
  },
  methods: {
    /* 判断是否有讲义 */
    dealDocType(item, type) {
      let temp = false
      if (item.docList && item.docList.length > 0) {
        item.docList.forEach((element) => {
          if (element.docType == type) {
            temp = true
          }
        })
      }
      return temp
    },
    tChuang() {
      this.dialogVisible = false
    },
    stop() {
      return
    },
    // 观看重播按钮  切换课程章节播放
    gkcb(v) {
      const sub = {
        id: v.id,
        classId: this.classId,
        startTime: v.startTime,
        classProductLine: this.classProductLine,
        liveId: v.liveId,
        isTrial: v.isTrial,
        backAudition: v.backAudition,
        IsPurchase: this.IsPurchase,
        chapterName: v.title
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      const userInfo = getInfo()
      // 未登录可以直接去试听
      if (!userInfo) {
        if (v.isTrial == 1 && v.isLive == 3 && !this.IsPurchase) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`
          })
          window.open(routeUrl.href, '_blank')
          return
        }
        Vue.prototype.goLoginView(false)
        return
      } else {
        // 已登录试听
        if (v.isTrial == 1 && v.isLive == 3 && !this.IsPurchase) {
          this.limitCheck(v, data)
          return
        }
      }
      if (!this.IsPurchase && v.isTrial != 1) {
        // this.$message.error("尚未购买，请先去购买课程！");
        this.dialogVisible = true
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (v.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (v.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      this.limitCheck(v, data)
    },

    // 看课限制
    async limitCheck(item, data) {
      const { code, msg } = await courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      })
      if (code == 0) {
        const routeUrl = this.$router.resolve({
          path: `/livebofang?${data}`
        })
        window.open(routeUrl.href, '_blank')
      } else {
        this.lookDeatil = msg
        this.showLookDetail = true
      }
    },
    // 直播
    gkZb(v) {
      console.log(this.isFreeze, 'isFreeze')
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }

      const sub = {
        id: v.id,
        classId: this.classId,
        startTime: v.startTime,
        classProductLine: this.classProductLine,
        chapterName: v.title
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: v.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`,
            query: {
              id: item.id,
              startTime: item.startTime
            }
          })
          window.open(routeUrl.href, '_blank')
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    // 课堂作业
    cgeckTask(item) {
      let sId = item.sId
      let title = item.title
      if (this.ifClass) {
        title = item.contactList[0].sjName
        sId = item.contactList[0].cContactid
      }
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            this.ifClass ? 1 : 2,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId,
            this.cardCode
          )
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    /* 随堂资料 */
    goData(item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, '_blank')

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
    /* 点击查看详情 */
    seeDetails(item) {
      console.log(item, 'iiii')
      if (this.isFreeze == 1) {
        this.$message({
          type: 'error',
          message: '课程已冻结'
        })
        return
      }

      if (item.type == 1) {
        /* 直播 */
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}&classId=${this.id}&classProductLine=3&cardCode=${this.cardCode}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 2) {
        /* 录播 */
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}&classId=${this.id}&classProductLine=3&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 3) {
        /* 音频 */
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 4) {
        /* 图文 */
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 5) {
        /* 面授 */
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}`
        })
        window.open(routeUrl.href, '_blank')
      }
    }
    // 随堂资料
    /*  goData(item) {
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      // data/daclickackageDetail?packageId=579
      this.$router.replace(`/ketangziliao?id=${item.id}&typebs=1`);
    }, */
  }
}
</script>
<style lang="less" scoped>
.play-img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.noLive {
  color: #9fa4ad;

  .title,
  .time,
  .video {
    color: #9fa4ad !important;
  }
}

.audition {
  width: 48px;
  height: 20px;
  background: #006DE7;
  border-radius: 8px 8px 8px 8px;
  font-size: 10px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #006DE7;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
  opacity: 0.06;
}

.chapter-title {
  font-size: 16px;
  font-family: MicrosoftYaHei-, MicrosoftYaHei;
  font-weight: normal;
  color: #333333;
}

// 缩宽
.warpConss {
  width: 100%;
  height: auto;

  .warpCpons {
    // position: absolute;
    position: fixed;
    z-index: 2000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.6);

    div {
      width: 610px;
      height: auto;
      // margin-top: 400px;
      display: flex;
      z-index: 20;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: rgba(255, 255, 255, 0);

      .imga {
        width: 100%;
        height: 420px;
      }

      .imgb {
        width: 36px;
        height: 36px;
        margin-top: 30px;
        cursor: pointer;
      }

      span {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #6c90fe;
        margin-top: -160px;
      }
    }
  }

  // 单个内容展开项盒子
  .warpConskexqq {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 30px 0px;

    .warpConskexq {
      width: 100%;
      height: auto;
      padding: 1px;

      // 某个展开项
      p {
        width: auto;
        height: 13px;
        font-size: 15px;
        margin: 20px 0px 30px 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }

      .warpConskexqs {
        width: 1175px;
        height: 50px;
        margin: 10px auto;
        background: #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 左侧
        .warpConskexqsL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          div {
            width: 4px;
            height: 50px;
            background: #dddddd;
            margin-right: 27px;
          }

          .on {
            background: rgb(107, 187, 251);
          }

          span {
            width: auto;
            height: auto;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
        }

        // 右侧
        .warpConskexqsR {
          width: 320px;
          height: 32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          div {
            width: 90px;
            height: 32px;
            margin-right: 10px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.chat-list {
  padding: 0px 32px;

  .chat-item {
    width: 100%;
    // height: 70px;
    display: flex;
    align-items: center;
    margin-top: 12px;

    .item-title {
      // width: 1017px;
      padding: 8px 16px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;

      // background: #f6f6f6;
      .flex-c {
        width: 350px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
      }

      .time {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        margin-left: 10px;
        // margin-top: 12px;
      }

      .tryListion {
        width: 48px;
        height: 20px;
        color: #2586f5;
        border-radius: 8px;
        background-color: #F0F6FE;
        ;
        font-size: 12px;
        line-height: 10px;
        text-align: center;
        margin-left: 20px;
        padding: 5px;
      }

      /* 直播 */
      .liveIng {
        padding: 0px 4px;
        height: 20px;
        background: #ff5e51;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-left: 8px;

        .live {
          color: #fff;
          font-size: 12px;
          margin-right: 4px;
        }
      }

      /* 回放 */
      .video {
        color: #6c7079;
        font-size: 40px;
      }
    }

    /* 未开始  回放生成中 */
    .noLive {
      color: #9fa4ad;

      .title,
      .time,
      .video {
        color: #9fa4ad !important;
      }
    }

    .but-list {
      flex-shrink: 0;
      display: flex;
      width: 300px;
      justify-content: flex-end;

      .keep-learn {
        margin-right: 10px;
        flex-shrink: 0;
        border: 1px solid #2586F5;
        color: #2586F5;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .chat-item:last-child {
    margin-bottom: 8px;
  }
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
